import React, { useState } from "react";
import {
  WalletIcon,
  ShoppingCartIcon,
  CheckIcon,
  DocumentTextIcon,
  PencilSquareIcon
} from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import {
  useAsrDayStatsQuery,
  useAsrOrderQuery,
  useAsrProfileQuery,
} from "../../app/apiSlices/clientSlice";
import { OrderItem } from "../DB/Orders";
import { CARD1 } from "./Dashboard";
import ASROrderModal from "../Modals/ASROrderModal";
import Pagination from "../Pagination";
import DownloadReport from "../Modals/DownloadReport";
import ASRUpdateModal from "../Modals/ASRUpdateModal";

const ASRProfile = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<IAsrOrder | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: asrProfile, refetch } = useAsrProfileQuery({ id });
  const { data: dayStats } = useAsrDayStatsQuery({ id });
  const { data: asrOrders, isLoading } = useAsrOrderQuery(
    { id, page: currentPage },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const bringUpModal = (order: IAsrOrder) => {
    setCurrentOrder(order);
    setShow(true);
  };

  const handleReport = () => setShowDownload(true);

  if (!asrProfile || !asrOrders)
    return (
      <div className="flex items-center justify-center py-10 w-full mx-auto">
        <svg
          aria-hidden="true"
          className=" w-8 h-8 text-gray-200 animate-spin  fill-white"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    );

  const { pages } = asrOrders?.data;

  return (
    <>
      <ASROrderModal
        show={show}
        setShow={setShow}
        order={currentOrder}
        asr={asrProfile}
      />
      <ASRUpdateModal
        show={showUpdate}
        setShow={setShowUpdate}
        id={id}
        refetch={refetch}
        />
      <DownloadReport
        show={showDownload}
        setShow={setShowDownload}
        id={id}
        name={asrProfile.name}
      />
      <section className="w-full max-w-7xl mx-auto">
        {/* Profile data */}
        <div className="relative flex flex-col gap-2 items-center justify-center w-[400px] mx-auto my-10 py-4 bg-white">
          <button
            type="button"
            className="justify-center"
            onClick={() => {
              setShowUpdate(true);
            }}
          >
            <PencilSquareIcon
              className="absolute top-4 md:top-10 right-4 md:right-6 h-6 w-6 border-none focus:outline-none ring-0"
              aria-hidden="true"
            />
          </button>
          <div className="w-20 h-20 rounded-full border-4 border-[#4e5661] flex items-center justify-center">
            <p className="text-blue text-xl font-semibold">
              {asrProfile.name[0].toUpperCase()}
            </p>
          </div>
          <p className="text-[#3A3939] text-xl">{asrProfile.name}</p>
          <p className="text-[#ACACAC] text-sm">
            Account created on: {asrProfile.created_at.split("T")[0]}
          </p>
          <p className="text-blue">
            Email address:{" "}
            <span className="font-semibold">{asrProfile.email}</span>
          </p>
          <p className="text-blue">
            House address:{" "}
            <span className="font-semibold">{asrProfile.address}</span>
          </p>
          <p className="text-blue">
            Phone number:{" "}
            <span className="font-semibold">{asrProfile.phone}</span>
          </p>
          <p className="text-blue">
            DB:{" "}
            <span className="font-semibold">
              {asrProfile.distributor.name} ({asrProfile.subregion.region.name})
            </span>
          </p>
          <p className="text-blue">
            Number of Retailers:{" "}
            <span className="font-semibold">{asrProfile.retailer_count}</span>
          </p>
          <div
            className="bg-[#2E4057] px-6 py-2 text-[#FFFFFF] rounded-full w-fit cursor-pointer"
            onClick={handleReport}
          >
            View Report
          </div>
        </div>
        {/* Stats card */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 my-8">
          {/* Card 1 */}
          <CARD1
            title="Today's money"
            value={dayStats?.totalSales || "0"}
            Icon={WalletIcon}
          />
          <CARD1
            title="Today's Completed Orders"
            value={dayStats?.completedOrders || "0"}
            Icon={CheckIcon}
          />
          <CARD1
            title="Today's Pending Orders"
            value={dayStats?.pendingOrders || "0"}
            Icon={DocumentTextIcon}
          />
          <CARD1
            title="Total Sales this Month"
            value={dayStats?.monthTotalSales || "0"}
            Icon={ShoppingCartIcon}
          />
        </div>
        {/* Table of Orders */}
        <div className="overflow-x-auto w-full mb-10">
          {pages > 1 && !isLoading && (
            <Pagination
              nPages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          <table className="w-full">
            <thead className="bg-white border-b border-gray-200">
              <tr className="text-[#2E4057] font-light">
                <th className="p-4 text-sm font-medium text-left">Order ID</th>
                <th className="p-4 text-sm font-medium text-left">Date</th>
                <th className="p-4 text-sm font-medium text-left">Retailer</th>
                <th className="p-4 text-sm font-medium text-left">Status</th>
                <th className="p-4 text-sm font-medium text-left">
                  Payment Status
                </th>
                <th className="p-4 text-sm font-medium text-left">Amount</th>
                <th className="p-4 text-sm font-medium text-left">Link</th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {asrOrders?.data.orders.map(
                (item: IAsrOrder, index: React.Key | null | undefined) => (
                  <tr className="" key={index}>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      {item.order_number}
                    </td>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      {item.created_at.split("T")[0]}
                    </td>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      {item.retailer_name}
                    </td>
                    <td className={`px-4 py-4 text-sm text-slate-400`}>
                      <p
                        className={`
                ${
                  item.status === "Completed"
                    ? "text-[#2CC56F] bg-green-200 px-4 py-2 w-fit rounded-full"
                    : item.status === "Pending"
                    ? "text-[#F2994A] bg-yellow-100 px-4 py-2 w-fit rounded-full"
                    : item.status === "Canceled"
                    ? "text-red-500 bg-red-200 px-4 py-2 w-fit rounded-full"
                    : ""
                }
                `}
                      >
                        {item.status}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      <p
                        className={`
                ${
                  item.payment_status === "Paid"
                    ? "text-[#2CC56F]"
                    : item.payment_status === "Unpaid"
                    ? "text-red-500"
                    : ""
                }
                  `}
                      >
                        {item.payment_status}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      {item.grand_total}
                    </td>
                    <td className="px-4 py-4 text-sm text-slate-400">
                      <div
                        className="bg-[#2E4057] px-6 py-2 text-[#FFFFFF] rounded-full w-fit cursor-pointer"
                        onClick={() => bringUpModal(item)}
                      >
                        View Order
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center py-10 w-full mx-auto">
              <svg
                aria-hidden="true"
                className=" w-8 h-8 text-gray-200 animate-spin  fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
          {!asrOrders?.data.orders.length && !isLoading && (
            <div className="flex items-center justify-center py-10 w-full mx-auto bg-white">
              <p className="text-gray-400 text-lg">No Orders yet</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ASRProfile;

export interface IAsrOrder {
  asr_id: number;
  created_at: string;
  distributor_id: number;
  grand_total: string;
  id: any;
  items: OrderItem[];
  order_number: string;
  payment_status: string;
  retailer_address: string;
  retailer_id: number;
  retailer_name: string;
  retailer_phone: string;
  retailer_store_name: string;
  status: string;
}
