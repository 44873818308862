import React from "react";
import { NavLink } from "react-router-dom";
import {
  WalletIcon,
  HomeIcon,
  ChartBarIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { logout } from "../../app/userSlice";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../app/apiSlices/dbSlice";
import toast from "react-hot-toast";

const SideBar = () => {
  const dispatch = useDispatch();
  const [logoutAPI] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      toast.loading("Logging out...", { id: "logout" });
      await logoutAPI().unwrap();
      dispatch(logout());
      toast.success("Logout successful", { id: "logout" });
    } catch (error) {
      toast.error("Failed to logout", { id: "logout" });
    }
  };
  return (
    <>
      <div className="flex flex-col items-start gap-[10px] h-full w-full">
        <h1 className="p-4 text-[#2D3748] font-semibold text-[14px]">
          DB DASHBOARD
        </h1>
        <div className="flex flex-row md:flex-col flex-wrap items-start gap-y-6 w-full sm:mt-8">
          <NavLink
            to="/db/dashboard"
            className={({ isActive }) =>
              "flex items-center w-fit trans " +
              (isActive ? "bg-[#2E4057] text-white" : "text-blue")
            }
          >
            <div className="bg-inherit text-inherit p-2 overflow-hidden">
              <HomeIcon className="h-6 w-6 text-inherit" />
            </div>
            <div className="w-full bg-[#F8F9FA] p-2 text-blue font-medium">
              <p className="ml-2">Dashboard</p>
            </div>
          </NavLink>

          <NavLink
            to="/db/orders"
            className={({ isActive }) =>
              "flex items-center w-fit trans " +
              (isActive ? "bg-[#2E4057] text-white" : "text-blue")
            }
          >
            <div className="bg-inherit text-inherit p-2 overflow-hidden">
              <ChartBarIcon className="h-6 w-6 text-inherit" />
            </div>
            <div className="w-full bg-[#F8F9FA] p-2 text-blue font-medium">
              <p className="ml-2">Orders</p>
            </div>
          </NavLink>
          <NavLink
            to="/db/inventory"
            className={({ isActive }) =>
              "flex items-center w-fit trans " +
              (isActive ? "bg-[#2E4057] text-white" : "text-blue")
            }
          >
            <div className="bg-inherit text-inherit p-2 overflow-hidden">
              <WalletIcon className="h-6 w-6 text-inherit" />
            </div>
            <div className="w-full bg-[#F8F9FA] py-3 px-2 text-blue font-medium">
              <p className="ml-2">Inventory</p>
            </div>
          </NavLink>
          <div
            onClick={handleLogout}
            className="flex items-center w-fit trans bg-[#F8F9FA] text-blue cursor-pointer"
          >
            <div className="bg-inherit text-inherit p-2 overflow-hidden">
              <ArrowLeftOnRectangleIcon className="h-6 w-6 text-inherit" />
            </div>
            <div className="w-full bg-[#F8F9FA] py-3 px-2 text-blue font-medium">
              <p className="ml-2">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
