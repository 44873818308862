import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

interface PaginationProps {
  nPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
}: PaginationProps) => {
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="flex sm:flex-row w-full max-w-7xl mx-auto py-8 flex-col justify-between gap-4 items-center sm:place-items-center border-gray-200 text-sm ">
      <div className="flex  justify-between items-center gap-2 ml-auto">
        <button
          className=" px-4 py-2 border border-gray-300 disabled:cursor-not-allowed disabled:bg-gray-100 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          {" "}
          <ArrowLeftCircleIcon className="h-4 w-4" />
        </button>
        <div>
          <span className="text-sm text-gray-700 ">
            <span className="font-semibold text-[#C7A755]">{currentPage}</span>{" "}
            / <span className="text-gray-900 ">{nPages}</span>
          </span>
        </div>
        <button
          className={`   items-center px-4 py-2 border disabled:bg-gray-100 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
          onClick={nextPage}
          disabled={currentPage === nPages}
        >
          <ArrowRightCircleIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
