import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { tokenRefresh, logout } from "../userSlice";
import { RootState } from "../store";
import { TokenRefreshPayload } from "../userSlice";

const BASE_URL = "https://api.hinterland.com.ng/";

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.access_token;

    if (token) {
      headers.set("Content-Type", "application/json");

      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

//Base query with automatic token refresh wrapper
const baseQueryWithReAuth = async (
  arg: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  /**
   * First, we run the baseQuery with the provided arguments.
   * If it fails with a 401 Unauthorized, we try to refresh the token.
   * If that succeeds, we run the baseQuery again with the same arguments.
   * If that fails, we log the user out.
   * If the first baseQuery succeeds, we return the result.
   */
  let result = await baseQuery(arg, api, extraOptions);
  if (result?.error?.status === 401) {
    const token = await baseQuery("auth/tokens/refresh/", api, extraOptions);
    if (token?.data) {
      let newToken = token.data as TokenRefreshPayload;
      api.dispatch(tokenRefresh(newToken));
      result = await baseQuery(arg, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
  }),
});
