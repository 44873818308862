import { Outlet } from "react-router-dom";
import NavbarDB from "./NavbarDB";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Sidebar from "./Sidebar";

const DBLayout = () => {
  const { loggedIn, subregion_id } = useSelector((state: RootState) => state.user);

    if (!loggedIn || !subregion_id) {
      return <Navigate to="/" replace />;
    }

  return (
    <div className="w-full bg-[#F8F9FA]">
      <div className="flex flex-col sm:flex-row items-start h-auto md:h-screen max-w-7xl mx-auto overflow-x-scroll">
        <div className="w-full sm:w-[16%] h-full">
          <Sidebar />
        </div>
        <div className="flex flex-col items-center w-full sm:w-[84%] h-full px-4">
          <NavbarDB />
          <div className="w-full h-full overflow-y-scroll mt-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DBLayout;
