import React from "react";
import { useLocation } from "react-router-dom";
import { DBCard } from "./Dashboard";

const AllDistributors = () => {
  const { state } = useLocation();
  const { title, data } = state;
  if (!title || !data) return null;

  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="py-8">
        <p className="text-[#2D3748] font-normal text-[28px] pb-6">
          Distributors across {title}
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
        {data.length > 0 &&
          data.map((db: any, index: React.Key | null | undefined) => (
            <DBCard
              name={db?.name}
              axis={db?.subregion.region.name}
              no_of_asrs={db.asr_count}
              no_of_retailers={db.retailers_count}
              id={db.id}
              key={index}
              db={db}
            />
          ))}
      </div>
    </div>
  );
};

export default AllDistributors;
