import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface SignUpSuccessModalProps {
  show: boolean;
    setShow: (show: boolean) => void;
    name: string;
}

const SignUpSuccessModal = ({ show, setShow, name }: SignUpSuccessModalProps) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[80%] sm:mx-auto h-full sm:h-auto px-5 md:px-28 max-w-xl transform overflow-hidden sm:rounded-[14px] py-16 bg-white text-left align-middle shadow-xl">
                  <div className="flex items-center w-20 h-20 rounded-full border-[#25CF7E] mx-auto my-6 border-[6px] bg-green-600">
                    <CheckIcon className="w-10 h-10 text-white mx-auto" />
                  </div>
                  <Dialog.Title
                    as="h2"
                    className=" w-full py-2 flex md:text-xl text-base justify-center "
                  >
                    <span className="text-center font-semibold text-[#3A3939]">
                      Retailers transferred successfully!
                    </span>
                    <button
                      type="button"
                      className="justify-center"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <XMarkIcon
                        className="absolute top-4 md:top-6 right-4 md:right-6 h-6 w-6 border-none focus:outline-none ring-0"
                        aria-hidden="true"
                      />
                    </button>
                  </Dialog.Title>
                  <p className="text-[#ACACAC] text-center text-[18px] font-light pb-6">
                    We have transferred all retailer controls from the old ASR
                    to{" "}
                    <span className="text-[#C7A755] font-semibold">{name}</span>{" "}
                    including order and transaction histories
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SignUpSuccessModal;
