import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { UserIcon } from "@heroicons/react/24/solid";
import { logout } from "../../app/userSlice";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../app/apiSlices/dbSlice";
import toast from "react-hot-toast";
import HomeDownload from "../HomeDownload";
const NavbarDB = () => {
  const [orderID, setOrderID] = useState("");

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const { pathname } = useLocation();
  const [useHome, setUseHome] = React.useState(false);
  const [path, setPath] = React.useState(pathname);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (pathname === "/db/") {
      setUseHome(true);
    } else {
      setUseHome(false);
      let p = pathname.split("/db/")[1];
      //set the first letter of p to a capital letter
      p = p.charAt(0).toUpperCase() + p.slice(1);
      setPath(p);
    }
  }, [pathname]);

  const [logoutAPI] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      toast.loading("Logging out...", { id: "logout" });
      await logoutAPI().unwrap();
      dispatch(logout());
      toast.success("Logout successful", { id: "logout" });
    } catch (error) {
      toast.error("Failed to logout", { id: "logout" });
    }
  };

  return (
    <nav className="flex w-full py-6 px-6 items-center justify-between">
      {/* Icon and title wrapper */}

      <div className="flex gap-6 justify-center">
        {/* Title */}
        <div className="flex flex-col justify-center gap-2">
          {useHome ? (
            <p className="text-[#A0AEC0] text-xs">
              Pages {"  "}
              <span className="text-blue">/ Home</span> <br />
            </p>
          ) : (
            <p className="text-slate-400 text-sm">
              Home {"  "}
              <span className="text-blue">/ {path}</span> <br />
            </p>
          )}
          {useHome ? (
            <h1 className="text-sm font-medium text-[#A0AEC0]">Dashboard</h1>
          ) : (
            <h1 className="text-sm font-medium text-[#2D3748] uppercase">
              {path}
            </h1>
          )}
        </div>
      </div>

      <div className="flex items-center gap-8">
        <HomeDownload />
        <div>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Order ID.."
              className="p-2 w-full text-sm text-gray-900 bg-transparent border rounded-xl border-gray-300 appearance-none focus:border-gray-900 focus:border-[1.5px] outline-none"
              value={orderID}
              onChange={(e) => setOrderID(e.target.value)}
            />
          </form>
        </div>
        {/* Logout button */}
        <div className="flex gap-4">
          <button
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-[#C7A755] rounded-xl"
            onClick={handleLogout}
          >
            <UserIcon className="w-4 h-4 text-[#718096]" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavbarDB;
