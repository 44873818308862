import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";

interface SignUpSuccessModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setShow3: (show: boolean) => void;
}

const SignUpSuccessModal = ({
  show,
  setShow,
  setShow3,
}: SignUpSuccessModalProps) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[80%] sm:mx-auto h-full sm:h-auto px-5 md:px-20 max-w-2xl transform overflow-hidden sm:rounded-[14px] py-16 bg-white text-left shadow-xl">
                  <Dialog.Title
                    as="h2"
                    className=" w-full py-6 flex md:text-[28px] text-base justify-start font-semibold"
                  >
                    <span className="text-center text-blue">
                      Transferring ASR account
                    </span>
                    <button
                      type="button"
                      className="justify-center"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <XMarkIcon
                        className="absolute top-4 md:top-6 right-4 md:right-6 h-6 w-6 border-none focus:outline-none ring-0"
                        aria-hidden="true"
                      />
                    </button>
                  </Dialog.Title>
                  <p className="text-[#A0AEC0] text-left font-medium pb-6">
                    This is to transfer the information and data of one ASR to
                    another ASR. Note that this means this current ASR will no
                    longer have access to the account or data.
                  </p>
                  <div
                    className="bg-[#2E4057] px-16 py-4 my-2 text-[#FFFFFF] rounded-md w-fit cursor-pointer font-medium"
                    onClick={() => {
                      setShow(false);
                      setTimeout(() => {
                        setShow3(true);
                      }, 500);
                    }}
                  >
                    PROCEED
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SignUpSuccessModal;
