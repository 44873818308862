import { apiSlice } from "../api/apiSlice";

export const clientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginClient: builder.mutation({
      query: (body) => ({
        url: "auth/login/client-agency",
        method: "POST",
        body,
      }),
    }),

    allAsr: builder.query<any, void>({
      query: () => ({
        url: "asr/list",
        method: "GET",
      }),
    }),

    allRetailers: builder.query<any, PaginatedOnlyQuery>({
      query: ({ page = 1 }) => ({
        url: `retailers?page=${page}`,
        method: "GET",
      }),
    }),

    homeStats: builder.query<any, void>({
      query: () => ({
        url: "/order/day-stats",
        method: "GET",
      }),
      transformResponse: (response: any, meta, arg) => response.data,
    }),

    asrProfile: builder.query<any, Base>({
      query: ({ id }) => ({
        url: `/asr/profile/${id}`,
        method: "GET",
      }),
      transformResponse: (response: any, meta, arg) => response.data,
    }),

    asrOrder: builder.query<any, PaginatedQuery>({
      query: ({ id, page = 1 }) => ({
        url: `/order/asr/list/${id}/?page=${page}`,
        method: "GET",
      }),
    }),

    asrDayStats: builder.query<any, Base>({
      query: ({ id }) => ({
        url: `/order/asr/${id}/day-stats`,
        method: "GET",
      }),

      transformResponse: (response: any, meta, arg) => response.data,
    }),

    allSubRegions: builder.query<any, Base>({
      query: ({ id = 1 }) => ({
        url: `region/subregions/${id}`,
        method: "GET",
      }),

      transformResponse: (response: any, meta, arg) => response.data.subregions,
    }),

    allRegions: builder.query<any, void>({
      query: () => ({
        url: "region/all",
        method: "GET",
      }),

      transformResponse: (response: any, meta, arg) => response.data,
    }),

    updateAsr: builder.mutation({
      query: ({ id, body }) => ({
        url: `asr/profile/${id}`,
        method: "PUT",
        body,
      }),
    }),

    asrReport: builder.mutation<any, any>({
      queryFn: async (
        { id = 1, start_day = "2023-02-01", end_day = "2023-02-28" },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result: any = await baseQuery({
          url: `report/asr/${id}?start_day=${start_day}&end_day=${end_day}`,
          responseHandler: (response) => response.blob(),
        });
        let hiddenElement = document.createElement("a");
        let url = window.URL || window.webkitURL;
        let blobPDF = url.createObjectURL(result?.data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = "_blank";
        hiddenElement.click();
        return { data: null };
      },
    }),
    generalReport: builder.mutation<any, any>({
      queryFn: async (
        {  start_day = "2023-02-01", end_day = "2023-02-28" },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result: any = await baseQuery({
          url: `report/general?start_day=${start_day}&end_day=${end_day}`,
          responseHandler: (response) => response.blob(),
        });
        let hiddenElement = document.createElement("a");
        let url = window.URL || window.webkitURL;
        let blobPDF = url.createObjectURL(result?.data);

        hiddenElement.href = blobPDF;
        hiddenElement.target = "_blank";
        hiddenElement.click();
        return { data: null };
      },
    }),
    dbReport: builder.mutation<any, any>({
      queryFn: async (
        {id=1,  start_day = "2023-02-01", end_day = "2023-02-28" },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result: any = await baseQuery({
          url: `report/distributor/${id}?start_day=${start_day}&end_day=${end_day}`,
          responseHandler: (response) => response.blob(),
        });
        let hiddenElement = document.createElement("a");
        let url = window.URL || window.webkitURL;
        let blobPDF = url.createObjectURL(result?.data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = "_blank";
        hiddenElement.click();
        return { data: null };
      },
    }),

    graph: builder.query<any, void>({
      query: () => ({
        url: "order/month-category-stats",
        method: "GET",
      }),

      transformResponse: (response: any, meta, arg) => response.data,
    }),
  }),
});

export const {
  useLoginClientMutation,
  useAllAsrQuery,
  useAllRetailersQuery,
  useHomeStatsQuery,
  useAsrProfileQuery,
  useAsrOrderQuery,
  useAsrDayStatsQuery,
  useAllSubRegionsQuery,
  useAllRegionsQuery,
  useUpdateAsrMutation,
  useAsrReportMutation,
  useGeneralReportMutation,
  useDbReportMutation,
  useGraphQuery,
} = clientApi;

export interface Base {
  id: string | undefined;
}

export interface PaginatedQuery extends Base {
  page: number | undefined;
}

export interface PaginatedOnlyQuery {
  page: number | undefined;
}

export interface ReportQuery extends Base {
  month: number;
  year: number;
}
