import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { OrderItem } from "../DB/Orders";
import { IAsrOrder } from "../Client/ASRProfile";

interface OrderProps {
    show: boolean;
    asr: any;
  order: IAsrOrder | null;
  setShow: (show: boolean) => void;
}

const ASROrderModal = ({ show, setShow, order, asr }: OrderProps) => {
  if (!order) return null;

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-scroll text-sm">
            <div className="flex min-h-screen items-center justify-center md:p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[30%] sm:ml-auto h-[96vh] overflow-y-scroll px-6 max-w-[350px] transform sm:rounded-[4px] py-2 bg-white text-left text-[12px] shadow-xl">
                  <button
                    type="button"
                    className="justify-center"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <XMarkIcon
                      className="absolute top-4 md:top-6 right-4 md:right-6 h-6 w-6 border-none focus:outline-none ring-0"
                      aria-hidden="true"
                    />
                  </button>
                  <Dialog.Title
                    as="h2"
                    className=" w-full pb-4 md:text-xl text-base text-start text-[#2E4057] font-medium"
                  >
                    Order Details
                  </Dialog.Title>
                  <div className="flex flex-col gap-2 text-[#3A3939] text-[12px]">
                    <div className="font-light">
                      Retailer:{" "}
                      <span className="ml-2 font-medium">
                        {order.retailer_name}
                      </span>
                    </div>

                    <div className="font-light">
                      Address:{" "}
                      <span className="ml-2 font-medium">
                        {order.retailer_address}
                      </span>
                    </div>

                    <div className="font-light">
                      Phone:{" "}
                      <span className="ml-2 font-medium">
                        {order.retailer_phone}
                      </span>
                    </div>

                    <div className="font-light">
                      ASR:{" "}
                      <span className="ml-2 underline font-light text-[#2D9CDB]">
                        {asr.name}
                      </span>
                      , <span>{asr.phone}</span>
                    </div>

                    <div className="font-light flex gap-4">
                      Payment Status:
                      <span
                        className={`${
                          order.payment_status === "Paid"
                            ? "text-[#27AE60]"
                            : order.payment_status === "Unpaid"
                            ? "text-[#EB5757]"
                            : "text-[#F2994A]"
                        }`}
                      >
                        {order.payment_status}
                      </span>
                    </div>

                    <div className="font-light flex gap-4 mb-4">
                      Order Status:{" "}
                      {
                        <span
                          className={`${
                            order.status === "Pending"
                              ? "text-[#F2994A]"
                              : order.status === "Completed"
                              ? "text-[#27AE60]"
                              : "text-[#EB5757]"
                          }`}
                        >
                          {order.status}
                        </span>
                      }
                    </div>
                  </div>

                  {order?.items.length > 0 && (
                    <div className="overflow-x-auto overflow-y-scroll">
                      <table className="w-full mt-4">
                        <thead>
                          <tr className="text-[#3A3939] text-[12px]">
                            <th className="p-2">Product</th>
                            <th className="p-2">Quantity</th>
                            <th className="p-2">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.items.map((item: OrderItem, index) => (
                            <tr
                              key={index}
                              className="text-[#3A3939] text-[12px] font-medium"
                            >
                              <td className="p-2 max-w-[150px]">{item.name}</td>
                              <td className="p-2">{item.quantity}</td>
                              <td className="p-2">{item.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {order.items.length > 0 && (
                    <div>
                      <div className="p-2 w-full flex justify-between my-8 font-semibold">
                        <div className="text-[#3A3939] text-[12px]">
                          Total Amount:
                        </div>
                        <div className="text-[#3A3939] text-[12px]">
                          {order.grand_total}
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ASROrderModal;
