import React from "react";
import { Toaster } from "react-hot-toast";
import { SignIn } from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ClientLayout,
  Dashboard,
  ASRList,
  RetailerList,
  Distributor,
  ASRProfile,
} from "./components/Client";
import { DashboardDB, DBLayout, Inventory, Orders } from "./components/DB";
import AllDistributors from "./components/Client/AllDistributors";

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />

          {/* Client Dashboard Routes */}
          <Route path="client/" element={<ClientLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="ASR" element={<ASRList />} />
            <Route path="asr-profile/:id" element={<ASRProfile />} />
            <Route path="retailers" element={<RetailerList />} />
            <Route path="distributor/:id" element={<Distributor />} />
            <Route path="distributors" element={<AllDistributors />} />
          </Route>
          {/* DB Dashboard Routes */}
          <Route path="db/" element={<DBLayout />}>
            <Route index element={<DashboardDB />} />
            <Route path="dashboard" element={<DashboardDB />} />
            <Route path="inventory" element={<Inventory />} />
            <Route path="orders" element={<Orders />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
