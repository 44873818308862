import { Dialog, Transition } from "@headlessui/react";
import { ChangeEvent, Fragment, Key, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDistributorListQuery } from "../../app/apiSlices/dbSlice";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import { useUpdateAsrMutation } from "../../app/apiSlices/clientSlice";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  id: any;
  refetch: any
}

const ASRUpdateModal = ({ show, setShow, id, refetch }: Props) => {
  const { data: dbList } = useDistributorListQuery();
  const [selectedID, setSelectedID] = useState<any>(null);
  const [updateASR, { isLoading }] = useUpdateAsrMutation();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
  });

  const handleFormDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedID(e.target.value);
  };

  const handleASRUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;
    let body = {
      name: formData.lastname + " " + formData.firstname,
      email: formData.email,
      phone: formData.phone,
      address: formData.address,
      distributor_id: parseInt(selectedID),
    };
    try {
      const res = await updateASR({ id, body: JSON.stringify(body) }).unwrap();
      refetch();
      setShow(false);
      toast.success("ASR Details updated");
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
      });
      setSelectedID(null);
    } catch (error: any) {
      toast.error(error?.data?.message ?? "Error updating details");
    }
  };
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[80%] sm:mx-auto h-full sm:h-auto px-5 md:px-10 max-w-2xl transform overflow-hidden sm:rounded-[4px] py-9 bg-white p-6 text-left align-middle shadow-xl">
                  <Dialog.Title
                    as="h2"
                    className=" w-full py-4 flex md:text-xl text-base"
                  >
                    <span className="text-left text-[#2E4057] text-[30px] font-semibold">
                      Update ASR Details
                    </span>
                    <button
                      type="button"
                      className="justify-center"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <XMarkIcon
                        className="absolute top-4 md:top-6 right-4 md:right-6 h-6 md:h-8 w-6 md:w-8 border-none focus:outline-none ring-0"
                        aria-hidden="true"
                      />
                    </button>
                  </Dialog.Title>

                  <div className="text-[#A0AEC0] font-normal py-6">
                    *Please ensure you are inputting the correct info
                  </div>

                  <form onSubmit={handleASRUpdate}>
                    <div className="flex flex-col gap-6 items-center w-full">
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="firstname">
                            First Name
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="First Name"
                            required
                            value={formData.firstname}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="lastname">
                            Last Name
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Last Name"
                            required
                            value={formData.lastname}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="email">
                            Email Address
                          </label>
                          <input
                            className="form-input"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email Address"
                            required
                            value={formData.email}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="phone">
                            Phone Number
                          </label>
                          <input
                            className="form-input"
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Phone Number"
                            required
                            value={formData.phone}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="address">
                            House Address
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="address"
                            id="address"
                            placeholder="House Address"
                            required
                            value={formData.address}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="email">
                            Select DB
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-input"
                            required
                            onChange={(e) => handleSelectOption(e)}
                          >
                            <option value="">Select a DB</option>
                            {dbList?.data &&
                              dbList?.data.map(
                                (db: any, index: Key | null | undefined) => (
                                  <option value={db.id} key={index}>
                                    {db.name}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="my-6 p-4 w-full max-w-[450px] mx-auto inline-flex items-center justify-center border border-transparent text-base font-medium rounded-xl text-white bg-[#2E4057]"
                      >
                        {isLoading ? <Loader text="Updating" /> : "UPDATE"}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ASRUpdateModal;
