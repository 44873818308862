import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Navbar from "./Navbar";

const CLientLayout = () => {
  const { loggedIn, role } = useSelector((state: RootState) => state.user);

  if (!loggedIn || !role) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden text-black bg-[#F8F9FA]">
      <Navbar />
      <div className="overflow-y-scroll scrollbar-hide py-4">
        <Outlet />
      </div>
    </div>
  );
};

export default CLientLayout;
