import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SmallGuinness } from "../../assets";
import ASRSignUpModal from "../Modals/ASRSignupModal";
import { UserIcon } from "@heroicons/react/24/solid";
import { useLogoutMutation } from "../../app/apiSlices/dbSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/userSlice";
import { RootState } from "../../app/store";
import TransferRetailersModal from "../Modals/TransferRetailersModal";
import ConfirmRetailerTransferModal from "../Modals/ConfirmRetailerTransferModal";
import HomeDownload from "../HomeDownload";

const Navbar = () => {
  //eslint-disable-next-line
  const { role } = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  const [useHome, setUseHome] = React.useState(false);
  const [path, setPath] = React.useState(pathname);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showTBtn, setShowTBtn] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutAPI] = useLogoutMutation();


  const handleLogout = async () => {
    try {
      await logoutAPI().unwrap();
      dispatch(logout());
      toast.success("Logout successful");
    } catch (error) {
      toast.error("Failed to logout");
    }
  };

  React.useEffect(() => {
    if (pathname === "/client/") {
      setUseHome(true);
    } else {
      setUseHome(false);
      let p = pathname.split("/client/")[1];
      //set the first letter of p to a capital letter
      p = p.charAt(0).toUpperCase() + p.slice(1);
      if (p.includes("/")) {
        p = p.split("/")[0];
      }
      setPath(p);
      if (p === "ASR") setShowBtn(true);
      if (p === "Asr-profile") setShowTBtn(true);
    }

    return () => {
      setShowBtn(false);
      setShowTBtn(false);
    }

  }, [pathname]);

  return (
    <div className="w-full max-w-7xl mx-auto">
      <ASRSignUpModal show={show} setShow={setShow} />
      <TransferRetailersModal show={show3} setShow={setShow3} />
      <ConfirmRetailerTransferModal show={show2} setShow={setShow2} setShow3={setShow3} />
      <nav className="flex w-full py-4 px-6 items-center justify-between">
        {/* Icon and title wrapper */}

        <div className="flex gap-6 justify-center">
          {/* Title */}
          <div className="flex flex-col justify-center">
            {useHome ? (
              <p className="text-slate-400 text-sm">
                Pages {"  "}
                <span className="text-blue">/ Home</span> <br />
              </p>
            ) : (
              <p className="text-slate-400 text-sm">
                <span
                  onClick={() => {
                    setShowBtn(false);
                    navigate("/client/");
                  }}
                  className="cursor-pointer"
                >
                  Home
                </span>{" "}
                {"  "}
                <span className="text-blue">/ {path}</span> <br />
              </p>
            )}
            {useHome ? (
              <h1 className="text-sm font-medium text-slate-500">Dashboard</h1>
            ) : (
              <h1 className="text-sm font-medium text-slate-500 uppercase">
                {path}
              </h1>
            )}
          </div>
          {/* Icon */}
          <div>
            <img src={SmallGuinness} alt="" />
          </div>
        </div>

        <div className="flex items-center justify-center gap-4">
          {/* ASR Sign up button when needed */}
          {showBtn && role === 4 && (
            <div
              className="border border-[#2E4057] text-[#2E4057] font-semibold px-6 py-2 rounded-xl cursor-pointer"
              onClick={() => setShow(true)}
            >
              Sign up ASR
            </div>
          )}
          {showTBtn && role === 4 && (
            <div
              className="border border-[#2E4057] text-[#2E4057] font-semibold px-6 py-2 rounded-xl cursor-pointer"
              onClick={() => setShow2(true)}
            >
              Transfer Retailers
            </div>
          )}

          <HomeDownload />

          {/* Download button */}
          <div className="flex gap-4">
            <button
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-[#C7A755] rounded-xl"
              onClick={handleLogout}
            >
              <UserIcon className="w-4 h-4 text-[#718096]" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
