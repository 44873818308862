import {
  WalletIcon,
  GlobeAltIcon,
  UsersIcon,
  ShoppingCartIcon,
  CheckIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { DBImage } from "../../assets";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  useDistributorListQuery,
  useUserCountQuery,
} from "../../app/apiSlices/dbSlice";
import DBSignUpModal from "../Modals/DBSignUpModal";
import ASRSignUpModal from "../Modals/ASRSignupModal";
import {
  useGraphQuery,
  useHomeStatsQuery,
} from "../../app/apiSlices/clientSlice";

const Dashboard = () => {
  const { role } = useSelector((state: RootState) => state.user);
  const { data: remDist } = useDistributorListQuery();
  const { data: userData } = useUserCountQuery();
  const { data: HomeStats } = useHomeStatsQuery();
  const { data: graph } = useGraphQuery();

  const [show, setShow] = React.useState(false);
  const [asrShow, setAsrShow] = React.useState(false);
  if (!graph || !remDist)
    return (
      <div className="flex items-center justify-center py-10 w-full mx-auto">
        <svg
          aria-hidden="true"
          className=" w-8 h-8 text-gray-200 animate-spin  fill-white"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    );

  const IkoroduToIjede = remDist?.filter(
    (dist: any) => dist.subregion.region.name === "Ikorodu to Ijede"
  );

  const IkoroduState = {
    title: "Ikorodu to Ijede",
    data: IkoroduToIjede,
  };
  const IkoroduToIjedeCount = IkoroduToIjede?.length;
  const Berger = remDist?.filter(
    (dist: any) => dist.subregion.region.name === "Otedola to Redeemed Camp"
  );
  const BergerCount = Berger?.length;

  const BergerState = {
    title: "Otedola to Redeemed Camp",
    data: Berger,
  };

  return (
    <div className="w-full max-w-7xl mx-auto">
      <DBSignUpModal show={show} setShow={setShow} />
      <ASRSignUpModal show={asrShow} setShow={setAsrShow} />
      {/* First column of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
        {/* Card 1 */}
        <CARD1
          title="Today's money"
          value={HomeStats?.totalSales.toString() || "..."}
          Icon={WalletIcon}
        />
        <CARD1
          title="Today's Completed Orders"
          value={HomeStats?.completedOrders.toString() || "..."}
          Icon={CheckIcon}
        />
        <CARD1
          title="Today's Pending Orders"
          value={HomeStats?.pendingOrders.toString() || "..."}
          Icon={DocumentTextIcon}
        />
        <CARD1
          title="Total Sales this Month"
          value={HomeStats?.monthTotalSales.toString() || "..."}
          Icon={ShoppingCartIcon}
        />
      </div>
      <p className="pt-8 text-blue font-medium px-2">Categories Breakdown</p>
      <p className="text-[#A0AEC0] pb-8 text-sm px-2">sold last month</p>
      {/* Second column of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {/* Card 1 */}
        <CARD1
          title="APNAD"
          value={graph[0]?.totalQty || "..."}
          Icon={GlobeAltIcon}
        />
        <CARD1
          title="BG"
          value={graph[1]?.totalQty || "..."}
          Icon={UsersIcon}
        />
        <CARD1
          title="MSS"
          value={graph[2]?.totalQty || "..."}
          Icon={UsersIcon}
        />
        <CARD1
          title="RTS"
          value={graph[3]?.totalQty || "..."}
          Icon={ShoppingCartIcon}
        />
      </div>

      {/* NEXT SECTION */}

      {/* Distributors Area */}
      <div className="w-full px-4 bg-[#ffffff]">
        <div className="flex items-center justify-between max-w-[650px] w-full mt-8 px-4 pt-6 pb-16">
          <div>
            <h3 className="text-[#2D3748] font-semibold text-[18px]">
              Distributors
            </h3>
            <p className="text-[#A0AEC0] text-sm">Across 2 Routes</p>
          </div>
          {role === 4 && (
            <div
              onClick={() => setShow(true)}
              className="uppercase cursor-pointer bg-[#2E4057] text-white px-8 py-2 border rounded-lg font-semibold text-[14px]"
            >
              Sign up DB
            </div>
          )}
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 bg-[#ffffff] gap-4 px-4">
          <AXIS_CARD
            axis="Ikorodu - Ijede"
            count={IkoroduToIjedeCount}
            state={IkoroduState}
          />
          <AXIS_CARD
            axis="Otedola to Redeemed Camp"
            count={BergerCount}
            state={BergerState}
          />
          <div className="flex flex-col justify-center items-center -mt-16">
            <h3 className="text-[#C7A755] font-semibold text-[30px]">
              {userData?.data?.retailer_count || "..."}
            </h3>
            <p className="text-[#2E4057] font-normal">Retailers</p>
            <div className="pt-4">
              <Link
                to={`/client/retailers`}
                className="text-[#2E4057] border border-[#2E4057] bg-white px-8 py-2 rounded-lg"
              >
                View all
              </Link>
            </div>
          </div>
          {role === 4 && (
            <div className="flex flex-col justify-center items-center">
              <h3 className="text-[#C7A755] font-semibold text-[30px]">
                {userData?.data?.asr_count || "..."}
              </h3>
              <p className="text-[#2E4057] font-normal">ASR</p>
              <div className="py-4">
                <Link
                  to={`/client/ASR`}
                  className="text-[#2E4057] border border-[#2E4057] bg-white px-10 py-2 rounded-lg"
                >
                  View all
                </Link>
              </div>
              <div
                className="border border-[#2E4057] bg-[#2E4057] text-white uppercase text-[14px] font-semibold px-6 py-2 rounded-xl cursor-pointer"
                onClick={() => setAsrShow(true)}
              >
                Sign up ASR
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

export interface ICard1 {
  title: string;
  value: string;
  Icon: any;
  percentage?: number;
}

export const CARD1 = ({ title, value, Icon, percentage }: ICard1) => (
  <div className="p-4 w-[80%] mx-auto md:w-[300px] flex justify-between items-center bg-white rounded-xl">
    <div className="flex flex-col gap-1">
      <h3 className="text-xs font-medium text-gray-900">{title}</h3>
      <div className="flex gap-2 items-center">
        <h3 className="text-sm font-semibold text-blue">{value}</h3>
        {percentage && (
          <h3
            className={`text-xs font-normal
          ${percentage < 0 ? "text-red-500" : "text-green-500"}`}
          >
            {percentage > 0 && "+"}
            {percentage < 0 && "-"}
            {percentage}%
          </h3>
        )}
      </div>
    </div>
    <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-lg bg-[#2E4057]">
      <Icon className="w-5 h-5 text-white" />
    </div>
  </div>
);

export interface DBProps {
  name?: string;
  axis?: string;
  no_of_asrs?: number;
  no_of_retailers: number;
  id: number;
  db: any;
}

export const DBCard = ({
  name,
  axis,
  no_of_asrs,
  no_of_retailers,
  id,
  db,
}: DBProps) => (
  <div className="h-[300px] w-[90%] shrink-0 snap-start snap-always rounded-xl">
    <div
      id="img"
      className="w-full h-[40%] rounded-md overflow-hidden relative"
    >
      <img
        src={DBImage}
        alt=""
        className="w-full h-full object-center object-cover"
      />
      <div className="bg-black fixed inset bg-opacity-80 " />
    </div>

    <div className="pt-4 space-y-2 h-full w-full px-4">
      <p className="text-[#A0AEC0] text-xs font-normal">{name}</p>
      <p className="text-[#2D3748] font-semibold text-[18px]">{axis}</p>
      <div className="text-[#A0AEC0] text-[12px] flex gap-2 mb-2">
        <span>{no_of_asrs} ASRs,</span>
        <span>{no_of_retailers} Retailers</span>
      </div>

      <div className="pt-4">
        <Link
          to={`/client/distributor/${id}`}
          state={{ db }}
          className="text-[#2E4057] border border-[#2E4057] px-4 py-2 rounded-lg"
        >
          View More
        </Link>
      </div>
    </div>
  </div>
);

interface axisProps {
  axis: string;
  count: number;
  state: {
    title: string;
    data: any[];
  };
}

const AXIS_CARD = ({ axis, count, state }: axisProps) => (
  <div className="h-[300px] w-[300px] rounded-xl">
    <div
      id="img"
      className="w-full h-[40%] rounded-md overflow-hidden relative"
    >
      <img
        src={DBImage}
        alt=""
        className="w-full h-full object-center object-cover"
      />
      <div className="bg-black fixed inset bg-opacity-80 " />
    </div>
    <div className="px-2">
      <p className="text-[#2D3748] font-semibold text-[18px] pt-4">{axis}</p>
      <p className="text-[#A0AEC0] text-xs font-normal py-4">
        {count} Distributors
      </p>
    </div>
    <div className="pt-4 space-y-2 h-full w-full px-4">
      <div className="pt-4">
        <Link
          to={`/client/distributors`}
          state={state}
          className="text-[#2E4057] border border-[#2E4057] px-4 py-2 rounded-lg"
        >
          View all Distributors
        </Link>
      </div>
    </div>
  </div>
);
