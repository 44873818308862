import { Dialog, Transition } from "@headlessui/react";
import { ChangeEvent, Fragment, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import SignUpSuccessModal from "./SignUpSuccessModal";
import {
  useAllRegionsQuery,
  useAllSubRegionsQuery,
} from "../../app/apiSlices/clientSlice";
import { toast } from "react-hot-toast";
import { useRegisterDistributorMutation } from "../../app/apiSlices/dbSlice";
import Loader from "../Loader";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

const DBSignUpModal = ({ show, setShow }: Props) => {
  const [selectedID, setSelectedID] = useState<any>(1);
  const [selectedID2, setSelectedID2] = useState<any>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const { data } = useAllRegionsQuery();
  const { data: subregions } = useAllSubRegionsQuery({ id: selectedID });
  const [registerDB, { isLoading }] = useRegisterDistributorMutation();

  const handleFormDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedID(e.target.value);
  };
  const handleSelectOption2 = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedID2(e.target.value);
  };

  const handleASRSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let body = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        subregion_id: parseInt(selectedID2),
      };
      await registerDB(body);
      setShow(false);
      setTimeout(() => {
        setShowSuccess(true);
      }, 500);
    } catch (error: any) {
      toast.error(error?.data?.message ?? "Error signing up");
    }
  };
  return (
    <>
      <SignUpSuccessModal show={showSuccess} setShow={setShowSuccess} />
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[80%] sm:mx-auto h-full sm:h-auto px-5 md:px-10 max-w-2xl transform overflow-hidden sm:rounded-[4px] py-9 bg-white p-6 text-left align-middle shadow-xl">
                  <Dialog.Title
                    as="h2"
                    className=" w-full py-4 flex md:text-xl text-base"
                  >
                    <span className="text-left text-[#2E4057] text-[30px] font-semibold">
                      Sign Up DB
                    </span>
                    <button
                      type="button"
                      className="justify-center"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <XMarkIcon
                        className="absolute top-4 md:top-6 right-4 md:right-6 h-6 md:h-8 w-6 md:w-8 border-none focus:outline-none ring-0"
                        aria-hidden="true"
                      />
                    </button>
                  </Dialog.Title>

                  <div className="text-[#A0AEC0] font-normal py-6">
                    *Please ensure you are inputting the correct info
                  </div>

                  <form onSubmit={handleASRSignUp}>
                    <div className="flex flex-col gap-6 items-center w-full">
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="email">
                            DB Email Address
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email Address"
                            required
                            value={formData.email}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="phone">
                            Phone Number
                          </label>
                          <input
                            className="form-input"
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Phone Number"
                            required
                            value={formData.phone}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="name">
                            DB Name
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="DB Name"
                            required
                            value={formData.name}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="address">
                            DB Address
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Store Address"
                            required
                            value={formData.address}
                            onChange={(e) => handleFormDataChange(e)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="email">
                            Select Region
                          </label>
                          <select
                            className="form-input"
                            required
                            onChange={(e) => handleSelectOption(e)}
                          >
                            <option value="">Select a Region</option>
                            {data?.map((region: IRegion, index: number) => (
                              <option key={index} value={region.id}>
                                {region.name}
                              </option>
                            ))}
                          </select>
                        </div>{" "}
                        <div className="flex flex-col w-full gap-1">
                          <label className="form-label" htmlFor="email">
                            Select SubRoute
                          </label>
                          <select
                            className="form-input"
                            required
                            onChange={(e) => handleSelectOption2(e)}
                          >
                            <option value="">Select a Route</option>
                            {subregions?.map(
                              (subRegion: ISubRegion, index: number) => (
                                <option key={index} value={subRegion?.id}>
                                  {subRegion?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="my-6 p-4 w-full max-w-[450px] mx-auto inline-flex items-center justify-center border border-transparent text-base font-medium rounded-xl text-white bg-[#2E4057]"
                      >
                        {isLoading ? <Loader text="Registering" /> : "REGISTER"}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DBSignUpModal;

export interface ISubRegion {
  code: string;
  id: number;
  name: string;
  region: IRegion;
  region_id: number;
}

export interface IRegion {
  id: number;
  name: string;
}
