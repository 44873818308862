import { apiSlice } from "../api/apiSlice";
import { Base, PaginatedQuery } from "./clientSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginDB: builder.mutation({
      query: (body) => ({
        url: "auth/login/distributor",
        method: "POST",
        body,
      }),
    }),

    logout: builder.mutation<any, void>({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),

    inventory: builder.query<any, void>({
      query: () => ({
        url: "product/all",
        method: "GET",
      }),
    }),

    orderList: builder.query<any, PaginatedQuery>({
      query: ({ id, page }) => ({
        url: `order/distributor/list/${id}/?page=${page}`,
        method: "GET",
      }),
    }),

    enableProduct: builder.mutation({
      query: (id) => ({
        url: `product/enable/${id}`,
        method: "PUT",
      }),
    }),

    disableProduct: builder.mutation({
      query: (id) => ({
        url: `product/disable/${id}`,
        method: "PUT",
      }),
    }),

    distributorList: builder.query<any, void>({
      query: () => ({
        url: "distributor/list",
        method: "GET",
      }),

      transformResponse: (response: any, meta, arg) => response.data,
    }),

    userCount: builder.query<any, void>({
      query: () => ({
        url: "user-counts",
        method: "GET",
      }),
    }),

    registerDistributor: builder.mutation({
      query: (body) => ({
        url: "auth/register/distributor",
        method: "POST",
        body,
      }),
    }),

    registerASR: builder.mutation({
      query: (body) => ({
        url: "auth/register/asr",
        method: "POST",
        body,
      }),
    }),

    dayStats: builder.query<any, Base>({
      query: (id) => ({
        url: `order/distributor/${id}/day-stats`,
        method: "GET",
      }),
    }),

    allCategories: builder.query<any, void>({
      query: () => ({
        url: "category/all",
        method: "GET",
      }),
    }),

    cancelOrder: builder.mutation({
      query: (id) => ({
        url: `order/status/${id}`,
        method: "PUT",
        body: {
          status: "Canceled",
        },
      }),
    }),

    acceptOrder: builder.mutation({
      query: (id) => ({
        url: `order/payment_status/${id}`,
        method: "PUT",
        body: {
          payment_status: "Paid",
        },
      }),
    }),
  }),
});

export const {
  useLoginDBMutation,
  useLogoutMutation,
  useInventoryQuery,
  useOrderListQuery,
  useEnableProductMutation,
  useDisableProductMutation,
  useDistributorListQuery,
  useUserCountQuery,
  useRegisterDistributorMutation,
  useRegisterASRMutation,
  useDayStatsQuery,
  useAllCategoriesQuery,
  useCancelOrderMutation,
  useAcceptOrderMutation,
} = authApi;
