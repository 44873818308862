import { createSlice, PayloadAction } from "@reduxjs/toolkit";



// Define the initial state using that type
const initialState: UserState = {
  _id: "",
  name: "",
  email: "",
  role: undefined,
  subregion_id: undefined,
  access_token: "",
  refresh_token: "",
  loggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    login: (state, action: PayloadAction<LoginPayload>) => {
      state._id = action.payload._id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.subregion_id = action.payload.subregion_id;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.loggedIn = true;
    },
    logout: () => initialState,

    tokenRefresh: (state, action: PayloadAction<TokenRefreshPayload>) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
  },
});

export const { login, logout, tokenRefresh } = userSlice.actions;

export default userSlice.reducer;



//Types and interfaces
export interface UserState {
  _id: string;
  name: string;
  email: string;
  role: number | undefined;
  subregion_id: number | undefined;
  access_token: string;
  refresh_token: string;
  loggedIn: boolean;
}

export interface LoginPayload {
  _id: string;
  name: string;
  email: string;
  role: number | undefined;
  subregion_id: number | undefined;
  access_token: string;
  refresh_token: string;
}

export interface TokenRefreshPayload {
  access_token: string;
  refresh_token: string;
}