import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { monthNames } from "../DB/Orders";
import { useAsrReportMutation } from "../../app/apiSlices/clientSlice";
import toast from "react-hot-toast";
import Loader from "../Loader";

interface DownloadReportProps {
  show: boolean;
  setShow: (show: boolean) => void;
  id: any;
  name: string;
}
const dates: string[] = [];

for (let i = 2023; i <= new Date().getFullYear(); i++) {
  dates.push(i.toString());
}

const DownloadReport = ({ show, setShow, id, name }: DownloadReportProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [getReport, { isLoading }] = useAsrReportMutation();
  const handleReport = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      if (!startDate || !endDate) return;
      const start_day = startDate.toISOString().split("T")[0];
      const end_day = endDate.toISOString().split("T")[0];

      await getReport({ id, start_day, end_day });
      setShow(false);
    } catch (error: any) {
      toast.error(error?.data?.message ?? "Error downloading report");
    }
  };
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[90%] sm:w-[80%] sm:mx-auto h-full sm:h-auto px-5 md:px-16 max-w-xl transform overflow-hidden sm:rounded-[14px] py-16 bg-white text-left align-middle shadow-xl">
                  <Dialog.Title
                    as="h2"
                    className=" w-full py-2 flex md:text-xl text-base justify-center "
                  >
                    <span className="text-center text-blue font-semibold">
                      Download {name} Monthly Report
                    </span>
                    <button
                      type="button"
                      className="justify-center"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <XMarkIcon
                        className="absolute top-4 md:top-6 right-4 md:right-6 h-6 w-6 border-none focus:outline-none ring-0"
                        aria-hidden="true"
                      />
                    </button>
                  </Dialog.Title>
                  <div className="text-[#A0AEC0] font-normal py-6">
                    *Choose a timeframe for the report
                  </div>
                  <form onSubmit={handleReport}>
                    <div className="flex flex-col gap-6 items-center w-full">
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label htmlFor="start_date">Set Start Date</label>
                          <input
                            type="date"
                            name="start_date"
                            id="start_date"
                            className="form-input"
                            required
                            onChange={(e) =>
                              setStartDate(new Date(e.target.value))
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-6">
                        <div className="flex flex-col w-full gap-1">
                          <label htmlFor="end_date">Set End Date</label>
                          <input
                            type="date"
                            name="end_date"
                            id="end_date"
                            className="form-input"
                            required
                            onChange={(e) =>
                              setEndDate(new Date(e.target.value))
                            }
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="my-6 p-4 w-full max-w-[450px] mx-auto inline-flex items-center justify-center border border-transparent text-base font-medium rounded-xl text-white bg-[#2E4057]"
                      >
                        {isLoading ? <Loader text="Downloading" /> : "DOWNLOAD"}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DownloadReport;
