import { useState } from "react";
import LastModal from "./Modals/GeneralReport";

const HomeDownload = () => {
  const [showDownload, setShowDownload] = useState(false);
  return (
    <>
      <LastModal show={showDownload} setShow={setShowDownload} />
      <div
        onClick={() => setShowDownload(true)}
       className="cursor-pointer px-4 py-2 w-fit inline-flex items-center justify-center border border-transparent text-base font-medium rounded-xl text-white bg-[#2E4057]"
      >
        General Report
      </div>
    </>
  );
};

export default HomeDownload;
